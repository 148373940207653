document.addEventListener("DOMContentLoaded", () => {
  const form = document.getElementById("discuss-form");
  const name = form.querySelector("input[name='name']");
  const email = form.querySelector("input[name='email']");
  const overlay = form.querySelector(".overlay");
  const loader = form.querySelector(".loader");
  const toast = document.querySelector(".toast");
  const modalLink = form.querySelector(".modal-link");
  const infoButton = document.querySelector("button.info-button");

  const agreement = form?.querySelector("#discussAgreement");
  const submitButton = form?.querySelector(".form-container-button");

  if (agreement && submitButton) {
    agreement.addEventListener("click", (e) => {
      if (e.target.checked) {
        submitButton.disabled = false;
      } else {
        submitButton.disabled = true;
      }
    });
  }

  modalLink.addEventListener("click", openModal);

  form.addEventListener("input", formInputChecker);

  window.submitForm = function () {
    console.log("submit");

    let isNameValid = checkField(name),
      isConnectValid = checkField(email);

    let isFormValid = isNameValid && isConnectValid;

    if (isFormValid) {
      overlay.classList.add("active");
      loader.classList.add("active");
      console.log("sending data...");
      sendDataToGoogleSheets(form, removeOverlayAndLoader, () =>
        showToast(toast)
      );
    }
  };

  const removeOverlayAndLoader = () => {
    overlay.classList.remove("active");
    loader.classList.remove("active");
  };

  infoButton.addEventListener("click", (e) => {
    e.preventDefault();
    Calendly.showPopupWidget("https://calendly.com/eugenia-ivanova/30min");
  });
});
